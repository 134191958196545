
module.exports = {
  firebase:{
    apiKey: "AIzaSyDHPsw16_muoCU_7miWvHeJyAat7jtP4ys",
    authDomain: "fir-dashboard-d788d.firebaseapp.com",
    databaseURL: "https://fir-dashboard-d788d-default-rtdb.firebaseio.com",
    projectId: "fir-dashboard-d788d",
    storageBucket: "fir-dashboard-d788d.appspot.com",
    messagingSenderId: "288732564078",
    appId: "1:288732564078:web:01bf1d1697b8a775dff47f",
    measurementId: "G-JTNDXFW3VF"
  },
  url:{
    clerkOffice: 'https://cms.ottawa.arsconnect.com/uploads/22_clerk_register_of_deeds_170d998c37.gif'
  }
}

