<template>
  <v-row no-gutters class="fill-height">
    <v-col cols="12">
      <menuMatrix></menuMatrix>
    </v-col>
  </v-row>
</template>

<script>
import menuMatrix from "@/components/menuMatrix.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    menuMatrix,
  },
  computed: {
    ...mapGetters(["appDimensions"]),
  },
};
</script>

