import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from './firebase/index';
import i18n from './i18n';
import VueSignature from "vue-signature-pad";
Vue.config.productionTip = false;
let app;

Vue.use(VueSignature);

auth.onAuthStateChanged(kiosk => {
  if (!app) {

    app = new Vue({
      router,
      store,
      vuetify,

      render: function (h) {
        return h(App, { props: {} })
      },
      i18n,
      beforeMount() {
        store.commit('setAppDimensions');
      }
    }).$mount('#app')
  }
  if (kiosk) {
    store.dispatch('fetchKioskProfile', kiosk);
    store.dispatch('manageKioskPresence', kiosk);
  }
})

