<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12" align="center" justify="center">
      <div class="video-panel" id="video-panel"></div>
    </v-col>
    <v-col cols="12" align="center" justify="center">
      <v-btn color="secondary" @click="endCall()" x-large>
        <v-icon class="pa-2">mdi-phone-hangup</v-icon>
        End Call
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
export default {
  name: "telepresence",
  computed: {
    ...mapGetters([
      "getKioskProfile",
      "getVideoCall",
      "getCallListener",
      "appDimensions",
    ]),
  },
  data() {
    return {
      api: null,
      callEndTimeout: 0,
    };
  },
  methods: {
    endCall() {
      setTimeout(() => {
        this.$store.dispatch("handleEndCall").then(() => {
          console.log("End Call is being called");
          this.api.executeCommand("hangup");
          this.getCallListener();
          this.$store.commit(
            "setCallStatus",
            "Please click on Video Call button to Start"
          );
          router.push("/");
        });
      }, 1000);
      clearTimeout(this.callEndTimeout);
    },
  },

  mounted() {
    const domain = "meet.arsconnect.com/" + this.getVideoCall.meetingCode;
    const options = {
      roomName: this.getVideoCall.meetingCode,
      width: this.appDimensions.width - 400,
      height: this.appDimensions.height - 150,
      parentNode: document.querySelector("#video-panel"),
      interfaceConfigOverwrite: {
        filmStripOnly: false,
        TOOLBAR_BUTTONS: [],
        TOOLBAR_ALWAYS_VISIBLE: false,
        HIDE_INVITE_MORE_HEADER: true,
        DISABLE_VIDEO_BACKGROUND: true,
        DEFAULT_BACKGROUND: "#FFF",
      },
      userInfo: {
        email: "",
        displayName: this.getKioskProfile.data().name,
        setTileView: true,
      },
    };
    window.JitsiMeetExternalAPI =
      window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
    this.api = new window.JitsiMeetExternalAPI(domain, options);
    this.api.addListener("participantLeft", () => {
      this.noOfParticipants = this.api.getNumberOfParticipants();
      console.log("NO OF PARTICIPANTS:", this.noOfParticipants);
      if (this.noOfParticipants === 1) {
        this.endCall();
      }
    });

    this.api.addListener("participantJoined", () => {
      this.noOfParticipants = this.api.getNumberOfParticipants();
      console.log("Total number of participant", this.noOfParticipants);
      if (this.noOfParticipants >= 1) {
        clearTimeout(this.callEndTimeout);
        console.log("Total number of participant speech deleted");
      }
    });
    console.log(this.api);
  },
};
</script>
