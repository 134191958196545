export default {
    state: {
        dataLoaded: false,
        apolloClient: undefined,
    },
    getters: {
        getDataLoaded: state => state.dataLoaded,
        getApolloClient: state => state.apolloClient
    },
    mutations: {
        setDataLoaded(state, status) {
            state.dataLoaded = status;
        },
    },
    actions: {}
    
}
