import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from "@/firebase";
import Home from '../views/Home.vue'
import login from '@/views/login.vue'
import videoCall from '@/views/videoCall.vue'
Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/videoCall',
    name: 'videoCall',
    component: videoCall,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next('/login');
  }
  else {
    next();
  }
})

export default router
