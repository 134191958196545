<template>
  <v-app v-resize="onResize">
    <v-main>
      <v-fade-transition leave-absolute>
        <router-view />
      </v-fade-transition>
    </v-main>

    <video-call-viewer></video-call-viewer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import VideoCallViewer from "./components/dialogs/videoCallViewer.vue";

export default {
  name: "App",
  data() {
    return {
      count: 0,
    };
  },
  components: {
    VideoCallViewer,
  },
  computed: {
    ...mapGetters([
      "appDimensions",
      "getKioskId",
      "getStartUserSession",
      "getTouchCount",
      "isUserLooking",
    ]),
  },
  methods: {
    onResize() {
      this.$store.commit("setAppDimensions");
    },
  },
  mounted() {
    window["console"]["log"] = function () {};
    console.warn = function () {};
  },
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444;
  width: 10px;
}

.v-card::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444;
}

.v-virtual-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  color: #d37444;
  width: 10px;
}

.v-virtual-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px #d37444;
}
.v-dialog {
  margin-top: 100px;
}
</style>
