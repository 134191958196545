<template>
  <v-row class="fill-height">
    <v-col cols="12" align-self="center">
      <v-card flat align="center">
        <v-img
          src="../assets/building-06.jpg"
          gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
          :height="appDimensions.height"
          :width="appDimensions.width"
        >
          <v-card-title
            class="white--text text-h2 justify-center font-text-bold mt-10"
          >
            Advanced Robot Solutions
          </v-card-title>
          <v-card-subtitle class="text-h5 justify-center white--text">
            Do we still need offices?
          </v-card-subtitle>
          <v-card-text> </v-card-text>

          <v-card
            :width="appDimensions.width / 3.75"
            :height="appDimensions.height / 3.25"
            class="my-10"
          >
            <v-card-title class="text-h5 justify-center mt-10">
              Tele-Video Conferencing
            </v-card-title>
            <v-card-actions class="justify-center my-5">
              <v-btn
                width="200"
                large
                color="blue"
                class="white--text"
                @click="initiateCall()"
              >
                <v-icon left medium>mdi-video</v-icon> Video Call</v-btn
              >
            </v-card-actions>
            <v-card-text class="text-subtitle-1">{{
              getCallStatus
            }}</v-card-text>
          </v-card>

          <v-card-actions class="buttonPos">
            <v-btn text @click="logout()"> logout </v-btn>
          </v-card-actions>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import telepresence from "@/components/telepresence";
export default {
  name: "menuMatrix",
  components: {
    telepresence,
  },
  computed: {
    ...mapGetters(["getKioskProfile", "appDimensions", "getCallStatus"]),
  },

  methods: {
    initiateCall() {
      this.$store.dispatch("getAssociatedGroups");
    },

    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
<style scoped>
.buttonPos {
  bottom: 0 !important;
  padding-top: 9cm;
  padding-left: 48cm;
}
</style>

