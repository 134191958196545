<template>
  <v-row class="fill-height">
    <v-col align-self="center">
      <telepresence></telepresence>
    </v-col>
  </v-row>
</template>
<script>
import Telepresence from "../components/telepresence.vue";
export default {
  name: "videoCall",
  components: {
    Telepresence,
  },
};
</script>